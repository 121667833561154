import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor as HI,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { EMPTY, from, Observable } from 'rxjs';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { CONTENT_TYPES } from 'src/app/shared/constants';
import { CacheService } from '../services/cache.service';
/**
 * Intercept http request and response to set loading
 */
@Injectable()
export class HttpInterceptor implements HI {
  /**
   * Routes which don't require tokens
   */
  unAuthorizedUrls: Array<string> = [
    '/token',
    '/user/register',
    'api/user/login',
    'api/user/forgotpassword',
    'api/user/recoverpassword',
    'api/user/custom-register',
  ];

  constructor(
    private loadingService: LoadingService,
    private cache: CacheService,
    private auth: AuthService
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (
      (err.status === 401 && !err.url?.includes('logout')) ||
      (err.status === 400 && err.url?.includes('/token')) || 
      (err.url?.includes('getprofile'))
    )
      this.auth.logout();
    this.loadingService.loadingState = false;
    if (err.url?.includes('logout')) return EMPTY;
    throw err;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingService.loadingState = true;
    if (new RegExp(this.unAuthorizedUrls.join('|')).test(request.url))
      return next.handle(request).pipe(
        this.handleLoadingState(),
        catchError((response) => this.handleAuthError(response))
      );
    return from(this.auth.refreshToken()).pipe(
      switchMap((access_token) => {
        request = request.clone({ url: request.url });
        if (!access_token) {
          return next.handle(request);
        }
        let headers = new HttpHeaders();
        if (request.url.includes('/token') || request.url.includes('/login'))
          headers = headers.set('Content-Type', CONTENT_TYPES.URLENCODED);
        else
          headers = headers.set('Content-Type', CONTENT_TYPES.APPLICATION_JSON);
        if (this.cache.access_token)
          headers = headers.set('Authorization', `Bearer ${access_token}`);
        const modifiedReq = request.clone({
          headers,
        });
        return next.handle(modifiedReq).pipe(
          retry(2),
          this.handleLoadingState(),
          catchError((response) => this.handleAuthError(response))
        );
      })
    );
  }

  /**
   * Handle loading state
   */
  handleLoadingState = () =>
    tap(
      (ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          this.loadingService.loadingState = false;
        }
      },
      (err: any) => {
        this.loadingService.loadingState = false;
      }
    );
}
