import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { IFormField } from "src/app/core/models/api.models";
import { Datatable, RequestDatatable } from "src/app/core/models/report.models";
import { ApiService } from "src/app/core/services/api.service";
import { CacheService } from "src/app/core/services/cache.service";
import { RouteService } from "src/app/core/services/route.service";
import {
  IApplicantsTable,
  IApplicationsTable,
  IAApplicationsTable,
} from "src/app/shared/models/application.models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  dataset: Datatable = <Datatable>{};
  noInstructionsTab: boolean = false;
  applicant: IApplicantsTable | null = null;
  applications: Array<IApplicationsTable> | null = null;
  IAapplications: Array<IAApplicationsTable> | null = null;
  applicationUpdated = new Subject(); // Subject to notify other tab application info is updated in other tabs
  private tabChange = new Subject<string>();
  unsavedChanges: any = {
    tab_t27: false,
    tab_t26: false,
    tab_t62: false,
    tab_t86: false,
    tab_t107: false,
  };
  private baseURL = `${environment.apiURL}`;
  private midURL = "report";
  private GET_DATATABLE = "/getdatatabledata";

  constructor(
    private api: ApiService,
    private cache: CacheService,
    private route: RouteService,
    private http: HttpClient
  ) {
    this.init();
  }

  init = async () => {
    // await this.loadApplicant();
    // this.loadApplications();
    this.clear();
  };

  tabListen = (tabName: string) =>
    this.tabChange.pipe(filter((tab) => tab === tabName));

  changeTab = (tabName: string) => {
    if (tabName === this.route.queryParams.tab) this.tabChange.next(tabName);
  };

  /**
   * Clear application data when route away
   */
  clear = () => {
    this.route
      .change()
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.route.currentRoute() === "/") {
          this.noInstructionsTab = false;
        }
      });
  };

  /**
   * Load applicant
   */
  loadApplicant = async () => {
    this.applicant = (
      await this.api
        .get<IApplicantsTable>({
          ApplicationTableId: await this.cache.findTable("Applicants"),
          ConditionGroups: [
            {
              Type: "all",
              Conditions: [
                {
                  ConditionField: {
                    Id: await this.cache.findField("Applicants", "Created By"),
                  },
                  OperationType: "is equal",
                  Value: (await this.cache.profileEmail())!,
                },
              ],
            },
          ],
        })
        .toPromise()
    )[0];
    return this.applicant;
  };

  /**
   * Load applications
   */
  loadApplications = async (applicantId: any) => {
    this.applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("Applications"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "Applications",
                    "Related Applicants"
                  ),
                },
                OperationType: "is equal",
                Value: applicantId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return this.applications;
  };

  /**
   * Load applications
   */
  loadAllISPApplications = async () => {
    if (!this.applicant) await this.loadApplicant();
    let applicantId = this.applicant ? this.applicant.id : "";
    this.applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("ISPApplications"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "ISPApplications",
                    "Related Applicants"
                  ),
                },
                OperationType: "is equal",
                Value: applicantId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return this.applications;
  };

  /**
   * Load ISP Additional applications - list of applications
   */
  loadAllISPAdditionalApplications = async () => {
    this.IAapplications = await this.api
      .get<IAApplicationsTable>({
        ApplicationTableId: await this.cache.findTable(
          "ISPAdditionalApplications"
        ),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [],
          },
        ],
      })
      .toPromise();
    return this.IAapplications;
  };

  /**
   * Load Community application
   */
  loadCommunityApplication = async (appId: any) => {
    if (!this.applicant) await this.loadApplicant();
    this.applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("Applications"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField("Applications", "Record Id"),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return this.applications;
  };

  /**
   * Load ISP applications
   */
  loadIspApplications = async (appId: number) => {
    if (!this.applicant) await this.loadApplicant();
    this.applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("ISPApplications"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "ISPApplications",
                    "Record Id"
                  ),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return this.applications;
  };

  /**
   * Load ISP applications
   */
  loadIspAdditionalApplications = async (appId: any) => {
    let additionalApplication = await this.api
      .get({
        ApplicationTableId: await this.cache.findTable(
          "ISPAdditionalApplications"
        ),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "ISPAdditionalApplications",
                    "Related ISPApplications"
                  ),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return additionalApplication[0];
  };

  /**
   * Load all applicants
   */
  loadApplicants = async () => {
    let documentation = await this.api
      .get<any>({
        ApplicationTableId: await this.cache.findTable("Applicants"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "Applicants",
                    "Applicant Type"
                  ),
                },
                OperationType: "is equal",
                Value: "Community",
              },
            ],
          },
        ],
      })
      .toPromise();
    return documentation;
  };

  /**
   * @returns state of instruction tab in the form of field in database
   */
  get instructionField(): IFormField {
    return {
      name: "no_instructions",
      value: this.noInstructionsTab,
    };
  }

  applicationStatus(
    app: any,
    saveMode: boolean = false,
    isSubmitting: boolean,
    isConfirmationCodeValid = true
  ): any {
    let condition = false;

    condition = Boolean(
      app.certified_minimum_transmission_speeds &&
        app.certified_low_income_programs &&
        app.certified_acp_participation &&
        app.certified_consult_needs &&
        app.certified_subsidies &&
        app.certified_prioritizing &&
        app.certified_no_dobs &&
        app.certified_prohibition &&
        app.certified_investment &&
        app.certified_fiber &&
        app.certified_connections &&
        app.certified_conflict_of_interest &&
        app.certified_monitoring
    );
    if (condition && !saveMode && isConfirmationCodeValid && isSubmitting) {
      return [
        {
          name: "resubmit",
          value: "False",
        },
        {
          name: "status",
          value:
            condition && !saveMode && isConfirmationCodeValid
              ? "Submitted"
              : "Open",
        },
        {
          name: "date_submitted",
          value: condition
            ? moment(new Date()).format("MM-DD-YYYY HH:mm:ss")
            : "",
        },
      ];
    } else {
      return [
        {
          name: "status",
          value:
            condition && !saveMode && isConfirmationCodeValid && isSubmitting
              ? "Submitted"
              : "Open",
        },
        {
          name: "date_submitted",
          value: condition
            ? moment(new Date()).format("MM-DD-YYYY HH:mm:ss")
            : "",
        },
      ];
    }
  }

  /**
   * Get datatable
   */
  getDatatable = (payload: RequestDatatable): Observable<Datatable> =>
    this.http.post<Datatable>(`${this.getDatatableUrl}`, payload, {});

  private get getDatatableUrl() {
    return `${this.baseURL}${this.midURL}${this.GET_DATATABLE}`;
  }

  /**
   * Load ISP applications
   */
  loadIspChallengeApplications = async (appId: number) => {
    if (!this.applicant) await this.loadApplicant();
    this.applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable(
          "Challenge Applications"
        ),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "Challenge Applications",
                    "Record Id"
                  ),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return this.applications;
  };

  /**
   * Load applications
   */
  loadCommThreadApplication = async (appId: any) => {
    let applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("Comm Threads"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField("Comm Threads", "Record Id"),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return applications;
  };

  /**
   * Load Outbound Messages
   */
  loadOutboundMessagesApplication = async (appId: any) => {
    let applications = await this.api
      .get<IApplicationsTable>({
        ApplicationTableId: await this.cache.findTable("Outbound Messages"),
        ConditionGroups: [
          {
            Type: "all",
            Conditions: [
              {
                ConditionField: {
                  Id: await this.cache.findField(
                    "Outbound Messages",
                    "Record Id"
                  ),
                },
                OperationType: "is equal",
                Value: appId,
              },
            ],
          },
        ],
      })
      .toPromise();
    return applications;
  };
}
