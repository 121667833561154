import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import {
  Select2Option,
  Select2UpdateEvent,
  Select2UpdateValue,
} from "ng-select2-component";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit, OnChanges {
  @Input("form") form!: FormGroup;
  @Input("label") label!: string;
  @Input("options") options: Array<any> | null = null;
  @Input("error") error: string = "This field is required";
  @Input("controlName") controlName: string = "";
  @Input("multiple") multiple: boolean = false;
  @Input("disabled") disabled: boolean = false;
  @Input("canClear") canClear: boolean = false;
  @Output("selected") selected = new EventEmitter<Array<Select2Option>>();
  @Output("clear") clear = new EventEmitter<void>();
  @Input("nullPlaceholder") nullPlaceholder: string = "Loading...";
  @Input() requiredMark: boolean = false;
  required = false;
  constructor() {}

  ngOnChanges(changes: any) {
    const disabled = changes?.disabled?.currentValue;
    // do not change below boolean check
    if (disabled === false) this.control.enable();
    else if (disabled === true) this.control.disable();
  }

  ngOnInit(): void {
    if (this.control?.errors?.required === true) this.required = true;
  }

  get control() {
    return this.form?.controls[this.controlName] || {};
  }

  /**
   * Check if control has required validator
   */
  get isRequired() {
    return this.control.hasValidator(Validators.required);
  }

  /**
   * Get state if data is loaded or not
   * @returns true if loading
   */
  isOptionLoading = () => this.options === null;

  /**
   * Options for select
   * @returns options
   */
  getOptions = () =>
    this.options
      ? this.multiple
        ? this.options
        : [{ value: null, label: "-- Select --" }, ...this.options]
      : [];
  /**
   * Placeholder 'type here' if options is null
   * @returns placeholder
   */
  getPlaceholder = () =>
    this.isOptionLoading() ? this.nullPlaceholder : "Select";

  /**
   * On change event
   */
  change = (event: Select2UpdateEvent<Select2UpdateValue>) => {
    this.selected.emit(event.options);
    if (this.disabled) this.control.disable();
  };

  clearSelect = () => {
    this.clear.emit();
  };
  /**
   * Set errors here priority wise
   * @return error message
   */
  getErrorMessage = () => {
    if (this.control.hasError("required")) return this.error;
    return "";
  };
}
