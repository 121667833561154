// export const environment = {
//   production: false,
//   applicationId: 914,
//   applicationKey: "x8xvllag1",
//   roleKey: "cxcbo59a1",
//   apiURL: "https://devapi.ignatius.io/api/",
//   secretKey: "x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H+M",
//   client_id: "IBIS",
//   captchaSiteKey: "6LeX0EUaAAAAACwj0vJ36bk1cJyK-OAG3XgwWASU",
//   deploymentURL: "ar-rural-connect-dev.herokuapp.com",
//   communityRole: "cxcbo59a1",
//   ispRole: "vps21tqw5",
// };

export const environment = {
  production: true,
  applicationId: 332,
  applicationKey: "htgpm6fw7",
  roleKey: "pzzcdraw5",
  apiURL: "https://api.ignatius.io/api/",
  secretKey: "x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H+M",
  client_id: "ARRuralConnect",
  captchaSiteKey: "6LeX0EUaAAAAACwj0vJ36bk1cJyK-OAG3XgwWASU",
  deploymentURL: "arkansasruralconnect.com",
  communityRole: "pzzcdraw5",
  ispRole: "ys1vd45g1",
  captchaSiteKeyV3: "6Lc2Z7UdAAAAAL8HwkjdbWS1OsNKBv2aUvlN1clV",
};
