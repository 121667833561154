import { Injectable, Injector } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StorageService } from "./storage.service";
import { RouteService } from "./route.service";
import { IToken } from "../models/auth.models";
import { CookieService } from "ngx-cookie";
import * as moment from "moment";
import { ApiService } from "./api.service";
import { CacheService } from "./cache.service";
import { Options } from "../models/api.models";
import { environment } from "src/environments/environment";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { CONTENT_TYPES } from "src/app/shared/constants";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public refreshTokenPromise: any = null;
  readonly IDLE_TIME = 30; // in sec
  readonly IDLE_TIMEOUT = 900; // in sec
  private _title: string = "";
  redirectUrl: any = "";
  language!: BehaviorSubject<string>;

  constructor(
    private storage: StorageService,
    private route: RouteService,
    private router: Router,
    private api: ApiService,
    private modal: NgbModal,
    private cookie: CookieService,
    private cache: CacheService,
    private http: HttpClient,
    private idle: Idle,
    private title: Title
  ) {
    this.cacheAuthData();
  }

  /**
   * Perform logout operation by clearing localstorage
   */
  logout = async () => {
    if (this.cache.isLoggedIn)
      await this.api.logout(this.cache.refresh_token!).toPromise();
    this.modal.dismissAll();
    this.storage.clear();
    this.cache.clearCache();
    this.route.changeTitle("AR Rural Connect");
    if (
      this.route.currentRoute() === "/" ||
      this.route.currentRoute().includes("auth")
    )
      this.route.navigate(["/auth", "login"]);
    else
      this.route.navigate(["/auth", "login"], {
        queryParams: {
          redirectTo: this.route.currentRoute(),
        },
      });
  };

  /**
   * Login using basic authentication
   * @param username
   * @param password
   */
  basicLogin = (
    username: string,
    password: string,
    recaptchaV3token: string,
    options?: Options,
  ): Observable<any> => {
    
    return this.api.login(username, password,recaptchaV3token, options);
  };

  /**
   * Checks if token is expired
   * @returns true if token is expired
   */
  private get isTokenExpired() {
    return moment(this.expires).diff(moment()) < 0;
  }

  private get expires() {
    return +this.cookie.get("expires") || 0;
  }

  /**
   * Save expiry unix timestamp to cookie
   */
  private saveExpiryToCookie = () => {
    this.cookie.put(
      "expires",
      moment(this.cache.tokenAPI?.[".expires"]!).format("x")
    );
  };

  /**
   * Get access token
   */
  getAccessToken = (): string | null => this.cache.access_token;

  /**
   * get refesh token
   */
  getRefreshToken = (): string | null => this.cache.refresh_token;

  /**
   * Refresh token
   * @returns promise
   */
  refreshToken = async (): Promise<string> => {
    if (!this.isTokenExpired)
      return Promise.resolve(this.getAccessToken() as string);
    const payload: any = {
      grant_type: "refresh_token",
      refresh_token: this.getRefreshToken()!,
      client_id: environment.client_id,
    };
    let params = new HttpParams();
    for (const creds in payload) params = params.set(creds, payload[creds]);
    if (!this.refreshTokenPromise)
      this.refreshTokenPromise = this.http
        .post<IToken>(`${environment.apiURL.split("api/")[0]}token`, params, {
          headers: {
            "Content-Type": CONTENT_TYPES.URLENCODED,
          },
        })
        .pipe(
          tap((tokenAPI: IToken) => {
            this.refreshTokenPromise = null;
            this.cache.tokenAPI = tokenAPI;
            this.saveExpiryToCookie();
            this.cache.cacheLoginState(tokenAPI);
            this.storage.saveToken(
              this.cache.access_token!,
              this.cache.refresh_token!
            );
            setTimeout(async () => {
              if ((await this.cache.getRole()).includes("Community"))
                this.idleWatch();
            }, 0);
            setTimeout(async () => {
              if (
                (await this.cache.getRole()).includes(
                  "Internet Service Provider"
                )
              )
                this.idleWatch();
            }, 0);
          }),
          map((tokenAPI: IToken) => tokenAPI.access_token)
        )
        .toPromise();
    return await this.refreshTokenPromise;
  };

  /**
   * Read and cache tokens in this service
   */
  private cacheAuthData = () => {
    const token = this.storage.accessToken;
    const refresh = this.storage.refreshToken;
    if (token && refresh) {
      this.cache.access_token = token;
      this.cache.refresh_token = refresh;
      this.cache.isLoggedIn = true;
      setTimeout(async () => {
        if ((await this.cache.getRole()).includes("Applicant"))
          this.idleWatch();
        if ((await this.cache.getRole()).includes("Internet Service Provider"))
          this.idleWatch();
      }, 0);
    }
  };

  idleWatch = () => {
    this.idle.setIdle(this.IDLE_TIME);
    this.idle.setTimeout(this.IDLE_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.title.setTitle(
        `${new Date(countdown * 1000)
          .toISOString()
          .substr(14, 5)} minutes idle time remaining before being logged out`
      );
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.title.setTitle(this._title);
    });
    this.idle.onTimeout.subscribe(() => {
      this.logout();
      this.idle.stop();
    });
    this.idle.watch();
  };

  changeLanguage(language: string) {
    this.language.next(language);
  }
}
