<div
  tabindex="-1"
  role="dialog"
  style="
    height: auto;
    width: 300px;
    right: 0;
    left: 0;
    top: 40%;
    margin: auto;
    z-index: 101;
    position: fixed;
  "
  class="
    ui-dialog
    ui-corner-all
    ui-widget
    ui-widget-content
    ui-front
    ui-dialog-buttons
    ui-draggable
    QB
    Dialog
    Untyped
    no-titlebar
  "
  aria-describedby="ui-id-1"
  aria-labelledby="ui-id-2"
  id="qbDialog"
>
  <div
    class="Contents ui-dialog-content ui-widget-content"
    id="ui-id-1"
    style="
      width: auto;
      min-height: 0px;
      max-height: 500px;
      height: auto;
      overflow-y: auto;
    "
  >
    <div id="qbDialogMessage" [innerHTML]="innerHTML"></div>
  </div>
  <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
    <button
      (click)="onClose.emit()"
      class="Vibrant Confirm Success ui-button ui-corner-all ui-widget"
    >
      OK
    </button>
  </div>
</div>
<div class="ui-widget-overlay ui-front" style="z-index: 100"></div>
