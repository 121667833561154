import { DatePipe } from "@angular/common";
import { OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ColumnMode,
  DatatableComponent as DC,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { forkJoin, Subject, Subscription } from "rxjs";
import { filter, map, take } from "rxjs/operators";

import { RouteService } from "src/app/core/services/route.service";
import {
  Column,
  Datatable,
  RequestDatatable,
  Summary,
} from "src/app/core/models/report.models";
// import { ReportService } from "src/app/core/state/_services/report.service";
import { SweetAlertOptions } from "sweetalert2";
import { DatatableColumn, Page } from "../../models/components.models";
import { ExportService } from "../../services/export.service";
// import { SearchService } from "../../services/search.service";
import {
  CSV,
  CSV_ALL,
  exportOptions,
  pageSizeOptions,
  XLSX,
} from "./datatable.constants";
import { FiltersComponent } from "./filters/filters.component";
import "moment-timezone";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment-timezone";
import { alertPresets, AlertService } from "../../services/alert.service";

@Component({
  selector: "app-ngx-datatable",
  templateUrl: "./datatable.component.html",
  styleUrls: ["./datatable.component.scss"],
  providers: [DatePipe],
})
export class DatatableComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Init pages to defaults
   */
  initPage = () => {
    const page = new Page();
    page.pageNumber = 0;
    page.size = 10;
    return page;
  };

  @ViewChild("checkbox", { static: false }) checkbox!: TemplateRef<any>;
  @ViewChild("default", { static: false }) default!: TemplateRef<any>;
  @ViewChild("myTable", { static: false }) table!: DC;
  @Input("columnMode") columnMode: ColumnMode = ColumnMode.force;
  @Input("dataset") dataset!: Datatable;
  @Input("reportId") dataTableReportId: number = 0;
  @Input("tableId") tableId: number = 0;
  @Input("columns") columns: Array<Column> | null = null;
  @Input("page") page: Page = this.initPage();
  @Input("actions") actions: TemplateRef<any> | null = null;
  @Input("pinActions") pinActions: boolean = true;
  @Input("columnOrder") columnOrder: Array<string> | null = null;
  @Input("columnOrderMode") columnOrderMode: "lazy" | "greedy" = "lazy";
  @Input() trigger: Subject<boolean> = new Subject<boolean>();
  @Input("summary") summary: Summary | null = null;
  @Output("payload") payload = new EventEmitter<RequestDatatable>();

  public renderTable: boolean = true;
  private DEBOUNCE_TIME = 500;
  private timeoutInstance: any = null;
  private currentPageNumber: number = this.page.pageNumber;
  public payloadSortBy: Array<any> = [];
  public payloadColumns: Array<any> = [];
  private subscribers: Array<Subscription> = [];
  private payloadSearch = {
    value: "",
    regex: false,
  };
  public isGroupingSelected: boolean = false;
  availableColumns: Array<DatatableColumn> = [];
  visibleColumns: Array<DatatableColumn> = [];
  rows: Array<any> = [];
  exportOptions = exportOptions;
  pageSizeOptions = pageSizeOptions;
  isGrouping: boolean = false;
  isColumnSearch: boolean = false;
  loadingState: boolean = true;
  isSelectable: boolean = false;
  SelectionType = SelectionType;
  selectedItems: Array<any> = [];
  groupedByColumn = false;
  isColumnGroupingEnable = false;
  previousTableId!: number;

  constructor(
    private route: RouteService,
    // private search: SearchService,
    private exportService: ExportService,
    private modal: NgbModal,
    // private report: ReportService,
    private date: DatePipe,
    private alert: AlertService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadSubscribers();
    this.subscribers.push(
      this.trigger.subscribe(() => {
        this.emitPayload();
      })
    );
  }

  /**
   * Detech changes of dataset
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // this.refreshTable();
    if (changes.dataset || changes.columns) {
      this.loadingState = false;
      this.generatePayloadColumns();
      this.initTable();
    }
    if (changes.summary) this.setGrouping();
  }

  /**
   * Subscribers
   */
  loadSubscribers = () => {
    let flag = false;
    // this.search.searchEvent.subscribe((text:any) => {
    //   if (!flag) {
    //     flag = true;
    //     return;
    //   }
    //   this.payloadSearch = {
    //     ...this.payloadSearch,
    //     value: text,
    //   };
    //   this.emitPayload();
    // });
  };

  /**
   * Set grouping on the basis of summary report
   * @returns
   */
  private setGrouping = () => {
    if (!this.summary) return;
    if (this.summary.GroupBy) {
      this.toggleGrouping();
      this.groupBy(`tbl${this.route.currentRoute()}_${this.summary.GroupBy}`);
    }
  };

  /**
   * Init datatable
   */
  private initTable = () => {
    if (!this.dataset || !Object.keys(this.dataset).length) return;
    this.parseColumns(
      this.columns
        ? this.makeColumnsFromPassed(this.columns)
        : this.makeColumnsFromDataset(this.dataset.Data)
    );
    if (this.groupedByColumn && this.groupByName != "") {
      this.groupArrayBy(this.dataset.Data, this.groupByName);
    } else {
      this.rows = [...this.dataset.Data];
    }

    this.page.totalElements = this.dataset.RecordsTotal;
    if (this.isColumnSearch) {
      this.rows.unshift({
        colSearch: true,
      });
      this.page.totalElements++;
    }
  };

  /**
   * @returns report id to update
   */
  get reportId() {
    return Number(this.route.paramMap.reportId);
  }

  /**
   * Datatable page event
   * @param event
   */
  pageListener = (event: any) => {
    this.clearSelection();
    this.currentPageNumber = event.offset;
    this.emitPayload();
  };

  /**
   * Sort listener
   */
  sortListener = (event: any) => {
    this.payloadSortBy = [
      {
        column: this.getSelectedColIndex(event.sorts[0].prop),
        dir: event.sorts[0].dir,
      },
    ];
    this.emitPayload();
  };

  /**
   * On filter submit
   */
  onFilter = () => {
    this.emitPayload();
  };

  /**
   * @returns index of column selected for sorting
   */
  getSelectedColIndex = (colName: string) =>
    this.payloadColumns.findIndex((col) => col.data === colName) || 0;

  /**
   * Get payload
   * @param pageNumber current page number
   * @param pageSize length of data per page
   * @returns payload
   */
  getPayload = (): RequestDatatable => {
    return <RequestDatatable>{
      ReportId: this.reportId,
      TableId: this.route.getTableId(),
      Draw: 1,
      Start: this.currentPageNumber * this.page.size,
      Length: this.page.size,
      Order: !this.payloadSortBy.length
        ? [
            {
              Column: -1,
              dir: "asc",
            },
          ]
        : this.payloadSortBy,
      columns: this.payloadColumns,
      search: this.payloadSearch,
      isGroupingEnabled: this.groupedByColumn ? true : false,
      IsQueryReport: false,
    };
  };

  /**
   * Make column array for payload
   * Generates column from first row of dataset
   */
  generatePayloadColumns = () => {
    if (
      !this.dataset ||
      !this.dataset.Data ||
      !this.dataset.Data.length ||
      this.payloadColumns.length
    )
      return;
    this.payloadColumns = [];
    const row = { ...this.dataset.Data[0] };
    for (let col in row)
      if (!col.includes("_url"))
        this.payloadColumns.push({
          data: col,
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        });
  };

  /**
   * Make columns from dataset if not explicitly passed
   */
  private makeColumnsFromDataset = (rows: Array<any>) => {
    const columns: Array<DatatableColumn> = [];
    for (let row in rows[0]) {
      let fieldName = row;
      while (fieldName.includes("tbl")) {
        fieldName = fieldName.split("_").splice(1).join("_");
      }
      columns.push({
        fieldName: row,
        name: fieldName.replace(/_/g, " "),
        prop: row,
      });
    }
    return columns;
  };

  /**
   * Make columns from explicitly passed columns
   */
  private makeColumnsFromPassed = (passed: Array<any>) => {
    const columns: Array<DatatableColumn> = [];
    for (let column of passed) {
      columns.push({
        fieldName: column.FieldName,
        name: (column.Name as string).split("-").slice(1).join("-").trim(),
        prop: column.FieldName,
        fieldType: column.FieldType,
        lookupTargetFieldId: column.LookupTargetFieldId,
      });
    }
    return columns;
  };

  /**
   * Create column data for datatable
   */
  private parseColumns = (columnData: Array<DatatableColumn>) => {
    if (!columnData.length) return;
    let columns: Array<DatatableColumn> = [];
    let conditions = ["id", "parent", "modif", "relate", "createdby"];
    //columns should not contain any conditions
    for (let o of columnData)
      if (!conditions.some((el) => o.fieldName.includes(el))) {
        columns.push({
          name: o.name.includes("datecreated") ? "Date Created" : o.name,
          prop: o.prop,
          fieldName: o.fieldName,
          fieldType: o.fieldType,
          lookupTargetFieldId: o.lookupTargetFieldId,
        });
      }
    let projectIdColumn = columnData.find((e: any) =>
      e.fieldName.includes("project_id")
    );
    if (projectIdColumn)
      columns.push({
        name: projectIdColumn.name,
        prop: projectIdColumn.prop,
        fieldName: projectIdColumn.fieldName,
        fieldType: projectIdColumn.fieldType,
        lookupTargetFieldId: projectIdColumn.lookupTargetFieldId,
      });

    this.visibleColumns = [];
    this.availableColumns = [];
    this.availableColumns = this.orderByColumnOrder(columns);
    this.visibleColumns = this.visibleColumns.length
      ? this.visibleColumns
      : [...this.availableColumns];
  };

  /**
   * Sort columns by columnOrder
   * @param columns
   */
  orderByColumnOrder = (columns: Array<DatatableColumn>) => {
    if (!this.columnOrder || !this.columnOrder.length) return columns;

    const columnMap: any = columns.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.fieldName]: currentValue,
      }),
      {}
    );

    const result = this.columnOrder
      .filter((key) => columnMap[key])
      .map((key) => {
        const o = { ...columnMap[key] };
        delete columnMap[key];
        return o;
      });

    if (this.columnOrderMode === "greedy")
      return result.concat(Object.keys(columnMap).map((key) => columnMap[key]));

    return result;
  };

  /**
   * Filter out parent and parent table id properties
   * from columns
   */
  filterParent = (column: any) =>
    !column.prop.includes("_parent_id") &&
    !column.prop.includes("_parent_table_id");

  /**
   * Toggle isGrouping
   * @param column's prop
   */
  toggleGrouping = () => {
    this.groupedByColumn = false;
    this.isColumnGroupingEnable = false;
    this.reRenderTable();
    this.isGroupingSelected = !this.isGroupingSelected;
    if (!this.isGroupingSelected) {
      this.payloadSortBy = [
        {
          column: 0,
          dir: "asc",
        },
      ];
      this.isGrouping = false;
    }
  };

  /**
   * Group by
   * @param column's prop
   */
  groupBy = (prop: string) => {
    if (this.isGroupingSelected) {
      this.isGrouping = true;
      this.payloadSortBy = [
        {
          column: this.getSelectedColIndex(prop),
          dir: "asc",
        },
      ];
      this.reRenderTable();
    }
  };

  toggleGroupingColumn(prop: string) {
    this.groupedByColumn = true;
    this.isGrouping = true;
    this.payloadSortBy = [
      {
        column: this.getSelectedColIndex(prop),
        dir: "asc",
      },
    ];
    this.reRenderTable();
  }

  groupArrayBy(originalArray: Array<object>, groupBy: string) {
    let priviousKeyValue: any;
    let tempArray: object[] = [];
    originalArray.forEach((item: any) => {
      item = { ...item };
      if (item[groupBy] !== "") {
        item[groupBy] =
          item[groupBy].charAt(0).toLocaleUpperCase() +
          item[groupBy].slice(1, item[groupBy].length).toLocaleLowerCase();
        priviousKeyValue = item[groupBy];
        tempArray.push(item);
      } else {
        item[groupBy] = priviousKeyValue;
        tempArray.push(item);
      }
      if (originalArray.length == tempArray.length) {
        this.rows = tempArray;
      }
    });
  }

  getTotalRecords(data: any) {
    const d = data.filter((e: any) => e.isgrouped === "true");
    if (d.length) {
      return d[0]?.tot ? d[0]?.tot : data?.length;
    }
    return data.length;
  }

  /**
   * Toggle column search
   */
  toggleColumnSearch = () => {
    this.isColumnSearch = !this.isColumnSearch;
    if (!this.isColumnSearch) {
      const payloadColumns = JSON.parse(JSON.stringify(this.payloadColumns));
      for (let col of payloadColumns) col.search.value = "";
      this.payloadColumns = payloadColumns;
      this.emitPayload();
    }
    this.initTable();
  };

  onColumnGroupingEnebled() {
    this.isColumnGroupingEnable = !this.isColumnGroupingEnable;
    this.reRenderTable();
    if (!this.isColumnGroupingEnable) {
      this.groupedByColumn = false;
      this.isGrouping = false;
    } else {
      this.isGroupingSelected = false;
      this.payloadSortBy = [
        {
          column: -1,
          dir: "asc",
        },
      ];
    }
  }

  /**
   * Emit payload
   */
  emitPayload = (useDebounce: boolean = false) => {
    if (this.isGrouping) this.rows = [];
    if (this.timeoutInstance) clearTimeout(this.timeoutInstance);
    this.timeoutInstance = null;
    if (useDebounce)
      this.timeoutInstance = setTimeout(() => {
        this.loadingState = true;
        this.payload.emit(this.getPayload());
      }, this.DEBOUNCE_TIME);
    else {
      this.loadingState = true;
      this.payload.emit(this.getPayload());
    }
  };

  /**
   * Button actions
   * @param option one of available export options
   */
  onExport = (option: string) => {
    const route = this.route.currentRoute();
    const fileName = route.includes("community")
      ? `Community Grant Applications`
      : route.includes("service")
      ? `ISP Grant Applications`
      : `Table=${this.route.getTableId()}_Report=${this.reportId}`;

    switch (option) {
      case XLSX:
        this.exportService.xlsx(fileName, "Sheet 1", this.rows);
        break;
      case CSV:
        this.exportService.csv(fileName, this.rows);
        break;
      // case CSV_ALL:
      //   this.exportFromBlob("csv");
      //   break;
    }
  };

  // /**
  //  * Export from blob
  //  * @param data
  //  */
  // exportFromBlob(exportType: string) {
  //   this.report
  //     .exportReport(this.route.getTableId(), this.reportId, exportType, -1)
  //     .pipe(take(1))
  //     .subscribe((data: any) => saveAs(data, "adaefd"));
  // }

  /**
   * Size of page selected
   */
  onPageSizeSelect = (size: number) => {
    this.page.size = size;
    this.emitPayload();
  };

  /**
   * Filter modal open
   */
  openFilters = () => {
    const modal = this.modal.open(FiltersComponent, {
      windowClass: "modal-custom",
      centered: true,
      size: "lg",
    });
    modal.componentInstance.availableColumns = this.availableColumns;
    modal.componentInstance.visibleColumns = this.visibleColumns;
    modal.componentInstance.selectedChange.subscribe(
      (columns: Array<DatatableColumn>) => {
        this.visibleColumns = columns;
      }
    );
  };

  /**
   * Column search input listener
   * @param event input event
   * @param column column properties
   */
  onColumnSearch = (event: any, column: DatatableColumn) => {
    if (event.target.value.length < 3) return;
    const columnIndex = this.payloadColumns.findIndex(
      (col) => col.data === column.prop
    );
    const payloadColumns = JSON.parse(JSON.stringify(this.payloadColumns));
    payloadColumns[columnIndex].search.value = event.target.value;
    this.payloadColumns = payloadColumns;
  };

  /**
   * toggle expand a group
   */
  toggleExpandGroup = (group: any) => {
    this.table.groupHeader.toggleExpandGroup(group);
  };

  /**
   * Select groupby name for grouping
   * @returns name of property to group with
   */
  get groupByName() {
    if (this.isGrouping && this.payloadColumns[this.payloadSortBy[0].column])
      return this.payloadColumns[this.payloadSortBy[0].column].data;
    return "";
  }

  /**
   * Re render the table (fixes grouping)
   */
  reRenderTable = () => {
    this.renderTable = false;
    setTimeout(() => {
      this.renderTable = true;
    }, 0);
  };

  /**
   * Convert field name to title name
   * @param fieldName
   */
  fieldToTitle = (fieldName: string) => fieldName.split("_").slice(1).join(" ");

  ngOnDestroy() {
    this.subscribers.forEach((s) => {
      s.unsubscribe();
    });
    this.subscribers = [];
  }

  /**
   * @returns true if hyperlink
   */
  isFile = (column: any) => {
    const col = this.columns?.find((col) => col.FieldName === column.prop);
    return col?.FieldType === "FILE";
  };

  /**
   * @returns true if hyperlink
   */
  isHyperlink = (column: any) => {
    const col = this.columns?.find((col) => col.FieldName === column.prop);
    return col?.LookupTargetFieldId || col?.IsSummaryField || col?.IsUrl;
  };

  /**
   * Open file in new tab
   * @param row
   * @param column
   */
  fileLink = (row: any, column: any) => {
    const col = this.columns?.find((col) => col.FieldName === column.prop);
    if (col?.FieldType === "FILE") {
      window.open(row[column.prop + "_url"], "_blank");
    }
  };

  /**
   * Generate route link for hyper link
   * @param value
   */
  routeLink = (row: any, column: any) => {
    const col = this.columns?.find((col) => col.FieldName === column.prop);
    const val = row[column.prop];
    if (col?.IsUrl && val) {
      const [appId, tableId, id, reportId] = val.split("/").slice(3);
      return [
        "/apps",
        appId,
        "tables",
        tableId,
        "reports",
        "details",
        "view",
        id,
        reportId,
      ];
    }
    if (col?.IsSummaryField) {
      return [
        "/apps",
        this.route.getApplicationId(),
        "tables",
        this.route.getTableId(),
        "reports",
        "details",
        "view",
        row["id"],
        this.reportId,
        col.Id,
      ];
    }
    const colName = column.prop;
    const colParentName = `${colName}_parent_id`;
    const colParentTableName = `${colName}_parent_table_id`;
    return [
      "/apps",
      this.route.getApplicationId(),
      "tables",
      row[colParentTableName],
      "reports",
      "details",
      "view",
      row[colParentName],
      0,
    ];
  };

  /**
   * Value to render
   * @param column
   * @param value
   */
  getValue = (column: DatatableColumn, value: string) => {
    if (
      column.prop.includes("datemodified") ||
      column.prop.includes("datecreated")
    ) {
      const date = this.date.transform(Number(value) * 1000, "MM/dd/yy, h:mm");
      return moment(date).tz("America/New_York").format("MM/DD/YYYY HH:mm:ss");
    } else {
      const col = this.columns?.find((col) => col.FieldName === column.prop);
      if (col?.HasLinkText) return col.LinkText;
      if (col?.FieldType === "DECIMAL(20,2)") return "$" + value;
      if (column.prop.includes("total_project_expenditure"))
        return `$ ${value}`;
      return value || "-";
    }
  };

  /**
   * Alignment of value
   */
  valueAlign = (column: DatatableColumn) => {
    const col = this.columns?.find((col) => col.FieldName === column.prop);
    if (col?.FieldType === "DECIMAL(20,2)") return "end";
    return "start";
  };

  /**
   * Renter template according to value
   */
  selectTemplate = (value: string) => {
    switch (value) {
      case "True":
      case "False":
        return this.checkbox;
      default:
        return this.default;
    }
  };

  changeSelectable = () => {
    this.isSelectable = !this.isSelectable;
    this.clearSelection();
  };

  /**
   * On row select by checkbox
   */
  onSelect = ({ selected }: any) => {
    this.selectedItems = selected || [];
  };

  deleteSelected = () => {
    this.alert.fire(<SweetAlertOptions>alertPresets.delete);
    // .then(async (res) => {
    //   if (res.isConfirmed) {
    //     try {
    //       const dependentRecords = await forkJoin(
    //         this.selectedItems.map((r) => {
    //           const payload: DependentRecords = {
    //             ApplicationId: this.route.getApplicationId(),
    //             ApplicationTableId: this.route.getTableId(),
    //             Where: {
    //               Rid: r.id,
    //             },
    //           };
    //           this.store.dispatch(
    //             new LoadDependentRecords(
    //               this.route.getTableId(),
    //               r.id,
    //               payload
    //             )
    //           );
    //           return this.store.pipe(
    //             select(getDependentRecords),
    //             filter(
    //               (records) =>
    //                 !!records[this.route.getTableId()] &&
    //                 !!records[this.route.getTableId()][r.id]
    //             ),
    //             map((records) => records[this.route.getTableId()][r.id]),
    //             take(1)
    //           );
    //         })
    //       ).toPromise();
    //       if (dependentRecords.some(({ data }) => Object.keys(data).length))
    //         this.alert
    //           .fire(<SweetAlertOptions>{
    //             ...alertPresets.delete,
    //             text: 'Your selection contains some dependent records which will also be deleted. Do you still want to continue?',
    //           })
    //           .then(async (res) => {
    //             if (res.isConfirmed) {
    //               await this.deleteSelectedRecords().toPromise();
    //               this.clearSelection();
    //               this.emitPayload();
    //             }
    //           });
    //       else {
    //         await this.deleteSelectedRecords().toPromise();
    //         this.clearSelection();
    //         this.emitPayload();
    //       }
    //     } catch (err) {}
    //   }
    // });
  };

  /**
   * Deleted selected records
   */
  deleteSelectedRecords = () => forkJoin(this.selectedItems.map((r) => {}));

  /**
   * Clear selected items
   */
  clearSelection = () => {
    this.selectedItems = [];
    this.table.selected = [];
  };

  getRowClass(row: any) {
    if (row.isgrouped == "true") return "selectedRow";
    else return "";
  }

  getCellClass(row: any) {
    if (row?.row.isgrouped == "true") return "removeCheckBox";
    else return "";
  }

  convertToDate = (value: string) => {
    const date = this.date.transform(
      Number(value) * 1000,
      "MM/dd/yy, h:mm a z"
    );
    return moment(date)
      .tz("America/New_York")
      .format("MM/DD/YYYY HH:mm:ss A zz");
  };

  /**
   * This is a bug in ngx-datatable which makes table do client side sorting even if "externalSorting" is true
   * Reference: https://github.com/swimlane/ngx-datatable/issues/1470#issuecomment-419357785
   */
  noClientSort = () => 0;

  refreshTable() {
    if (this.previousTableId != this.route.getTableId()) {
      this.groupedByColumn = false;
      this.isColumnGroupingEnable = false;
      this.isGrouping = false;
      this.previousTableId = this.route.getTableId();
      this.reRenderTable();
    }
  }
}
