import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { ApplicationService } from "src/app/modules/application/application.service";
import { environment } from "src/environments/environment";
import { RequestDatatable } from "../models/report.models";
import { AuthService } from "../services/auth.service";
import { CacheService } from "../services/cache.service";

@Injectable({
  providedIn: "root",
})
export class CanMoveResolver implements Resolve<boolean> {
  constructor(
    private cache: CacheService,
    private auth: AuthService,
    private application: ApplicationService,
    private router: Router
  ) {}
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    // const payload = this.makePayLoad();
    var reportId = 0;
    var tableId = 0;
    var env = {};
    var role = "";

    if (window.location.pathname !== "/") return of("not required");
    try {
      role = await this.cache.getRole();
    } catch (error) {
      return of("error");
    }
    if (role == "Community") {
      reportId = await this.cache.findReport("Applications", "listAll");
      tableId = await this.cache.findTable("Applications");
      env =
        "tbl" +
        (await this.cache.findTable("Applications")) +
        "_" +
        (await this.cache.findField(
          "Applications",
          "Related Applicants",
          "DbName"
        ));
    } else {
      reportId = await this.cache.findReport("ISPApplications", "listAllISP");
      tableId = await this.cache.findTable("ISPApplications");
      env =
        "tbl" +
        (await this.cache.findTable("ISPApplications")) +
        "_" +
        (await this.cache.findField(
          "ISPApplications",
          "Related Applicants",
          "DbName"
        ));
    }
    const applicant = await this.application.loadApplicant();
    if (applicant) {
      let payload: RequestDatatable = {
        TableId: tableId,
        ReportId: reportId,
        Draw: 1,
        Length: 10,
        Order: [{ column: -1, dir: "asc" }],
        Start: 0,
        IsQueryReport: false,
        Columns: [
          {
            Data: env,
            Name: "",
            Searchable: true,
            Orderable: true,
            Search: {
              Value: applicant!.id,
              Regex: true,
            },
          },
        ],
        IsGroupingEnabled: false,
        Search: { value: "", regex: false },
      };
      return await this.application.getDatatable(payload).toPromise();
    }
    return of("applicant not found error");
  }
}
