import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { CacheService } from './core/services/cache.service';
import { LoadingService } from './core/services/loading.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  //title = "ibis";
  idleState = "NOT_STARTED";
  countdown?: number;
  lastPing?: Date;
  idle: Idle;
  user: any;

  constructor(
    private auth: AuthService,
    private cache: CacheService,
    public loadingService: LoadingService,
    idle: Idle, 
    keepalive: Keepalive, 
    cd: ChangeDetectorRef
  ) {
    this.idle = idle;
    // set idle parameters
    idle.setIdle(300); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(1800); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      console.log(`${this.idleState} ${new Date()}`)
      this.countdown = undefined;
      cd.detectChanges();
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT"
      if (this.cache.isLoggedIn){
        this.idle.stop();
        this.auth.logout();
      }

    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date(); 
      this.user = this.cache.getProfile();
    });
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = undefined;
    this.lastPing = undefined;
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }
}
